import React from "react";
import { Helmet } from "react-helmet";
import ContentTheme from "./ContentTheme";
// import * as prism from "prismjs/themes/prism-tomorrow.css";

const Layout = ({ children }) => [
	<Helmet>
		<link rel="hub" href="https://webmaster.stevestreza.com/websub" />
		<link
			rel="webmention"
			href="https://webmaster.stevestreza.com/webmention"
		/>
		<link
			rel="pingback"
			href="https://webmention.io/webmention?forward=https://webmaster.stevestreza.com/webmention"
		/>
	</Helmet>,
	<ContentTheme layout={null}>{children}</ContentTheme>,
];
export default Layout;
