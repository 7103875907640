import React from "react";
import { ThemeProvider } from "styled-components";

const defaultTheme = {
	contentBackground: "white",
	contentTopBorder: "none",
	contentBottomBorder: "1px solid #d4d4d4",
	contentColor: "black",
	contentBoxShadow: "0px 2px 8px rgba(0, 0, 0, 0.3)",

	navigationBarBackground: "#efebf4",
	navigationBarBorder: "1px solid #d8cee5",
	navigationBarColor: "#50298f",

	headerBackground:
		"linear-gradient(to bottom, rgba(30, 15, 38, 0.6) 0%, rgba(26, 1, 38, 0.5) 5%, rgba(26, 1, 38, 0.60) 20%, rgba(1, 0, 1, 0.7) 98%, rgba(0, 0, 0, 0.76) 100%)",

	tagBackground: "#dedede",
	tagBorder: "#d0d0d0",
	tagColor: "#50298F",
};

const PostTheme = {
	...defaultTheme,
};

const VideoTheme = {
	...defaultTheme,
	contentBackground:
		"radial-gradient(ellipse at top, rgba(33, 25, 51, 1) 0%,rgba(21, 18, 30, 1) 60%)",
	contentBottomBorder: "1px solid #362D38",
	contentColor: "#DBD1E0",

	navigationBarBackground: "#262138",
	navigationBarBorder: "1px solid #372d4c",
	navigationBarColor: "#c5a9f2",
	navigationBarTextShadow: "none",

	contentShadow: "0px 1px 4px rgba(0, 0, 0, 0.94)",
	contentBoxShadow: "0px 0px 60px rgba(0, 0, 0, 0.3)",

	tagBackground: "#302833",
	tagBorder: "#47354c",
	tagColor: "#b172c1",
};

const FloatingTheme = {
	...defaultTheme,
	navigationBarBackground: "none",
	navigationBarBorder: "none",
	navigationBarColor: "#dfdaf2",
	navigationBarTextShadow: "0px 1px 4px black",
};

const ContentTheme = ({ layout = "post", children }) => {
	const theme = () => {
		if (!layout) {
			return defaultTheme;
		} else if (layout === "video") {
			return VideoTheme;
		} else if (layout === "floating") {
			return FloatingTheme;
		} else {
			return PostTheme;
		}
	};
	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
export default ContentTheme;
